export const courseNameWithFallbacks = (course) =>
  (course.catalogDisplayName || course.longName || course.name || '').trim()

export const programNameWithFallbacks = (program) =>
  (
    program.catalogDisplayName ||
    program.transcriptDescription ||
    program.longName ||
    program.name ||
    ''
  ).trim()
