import { computed, ref, watch, onBeforeUnmount } from '@nuxtjs/composition-api'
import _get from 'lodash/get'
import useConditionTypes from './useConditionTypes'
import useEffectTypes from './useEffectTypes'

export default function useFieldActions({
  field,
  formValue,
  fieldValue,
  update,
  questions,
  contextSources = ref({}),
} = {}) {
  const { evaluateCondition } = useConditionTypes()
  const { triggerEffects } = useEffectTypes({
    updateField,
    updateValue: update,
    field,
  })

  const fieldOverrides = ref([])
  const actions = field.value.actions || []
  const sources = computed(() => ({
    form: formValue.value,
    ...contextSources.value,
  }))

  const subscribers = actions.map((action) => {
    const items = action.logic.items?.length
      ? action.logic.items
      : [action.logic]
    const effects = action.effects
    const watchTargets = items.map((item) =>
      computed(() => {
        const dataSource = sources.value[item.field.source]

        const pathPartials = item.field.id.split('.')
        if (pathPartials.length > 1) {
          const [rootProp, ...nestedPropsArray] = pathPartials
          const nestedPropsPath = nestedPropsArray.join('.')
          const rootPropValue = dataSource[rootProp]
          if (!rootPropValue) return rootPropValue

          return Array.isArray(rootPropValue)
            ? rootPropValue.map((v) => _get(v, nestedPropsPath))
            : _get(rootPropValue, nestedPropsPath)
        } else {
          const itemField =
            questions.find((q) => q.questionId === item.field.id) || {}
          return dataSource[itemField.dataKey] || dataSource[item.field.id]
        }
      })
    )

    return watch(watchTargets, evaluateAction, { immediate: true })

    function evaluateAction(sourceValues) {
      const isActionSuccessful =
        action.logic.condition === 'allOf'
          ? items.every(
              evaluateCondition(sourceValues, fieldValue.value, formValue.value)
            )
          : items.some(
              evaluateCondition(sourceValues, fieldValue.value, formValue.value)
            )

      if (isActionSuccessful)
        triggerEffects(effects, action.logic.id, { sourceValues })
      else removeFieldChanges(action.logic.id)
    }
  })

  function updateField(changes, id) {
    if (fieldOverrides.value.find((override) => override.id === id)) return
    fieldOverrides.value.push({
      id,
      changes,
    })
  }

  function removeFieldChanges(id) {
    const index = fieldOverrides.value.findIndex(
      (override) => override.id === id
    )
    if (index >= 0) fieldOverrides.value.splice(index, 1)
  }

  onBeforeUnmount(() => subscribers.forEach((unwatch) => unwatch()))

  const fieldWithChanges = computed(() =>
    fieldOverrides.value.reduce(
      (changes, override) => {
        const mergedConfig = {
          ...changes.config,
          ...(override.changes.config || {}),
        }
        return {
          ...changes,
          ...override.changes,
          config: mergedConfig,
        }
      },
      { ...field.value }
    )
  )

  return {
    fieldWithChanges,
  }
}
