import { computed, ref, inject, provide } from '@nuxtjs/composition-api'

const effectTypesSymbol = Symbol('effectTypesSymbol')

export default function useEffectTypes({
  updateValue,
  updateField,
  field,
} = {}) {
  const BASE_EFFECT_TYPES = [
    {
      label: 'Update Field Value',
      key: 'valueChange',
      handler: valueChangeEffect,
    },
    {
      label: 'Update Field Config',
      key: 'configChange',
      handler: configChangeEffect,
    },
  ]
  const effectTypesArray = inject(effectTypesSymbol, ref(BASE_EFFECT_TYPES))
  provide(effectTypesSymbol, effectTypesArray)

  const effectTypes = computed(() =>
    effectTypesArray.value.reduce(
      (types, type) => ({ ...types, [type.key]: type }),
      {}
    )
  )

  function addTypes(typeOrTypes) {
    const types = Array.isArray(typeOrTypes) ? typeOrTypes : [typeOrTypes]
    effectTypesArray.value.push(...types)
  }

  function triggerEffects(effects, actionId, { sourceValues }) {
    effects.forEach(triggerEffect)

    function triggerEffect({ type, value }) {
      const effect = effectTypes.value[type].handler
      effect(value, actionId, { sourceValues, field, updateField, updateValue })
    }
  }

  function valueChangeEffect(value, actionId, { sourceValues, updateValue }) {
    let newValue = value
    if (typeof value === 'string' && value.includes('$_')) {
      const index = Number(value.slice(2))
      newValue = sourceValues[index]
    }
    updateValue(newValue)
  }

  function configChangeEffect(value, actionId, { updateField }) {
    updateField(value, actionId)
  }

  return {
    effectTypes,
    effectTypesArray,
    addTypes,
    triggerEffects,
  }
}
