import DefaultField from '@/components/Fields/DefaultField'
const TermField = () =>
  import(/* webpackChunkName: 'TermField' */ '@/components/Fields/TermField')
const WysiwygField = () =>
  import(/* webpackChunkName: 'WysiwygField' */ '@/components/Fields/WysiwygField')
const CourseCredits = () =>
  import(/* webpackChunkName: 'CourseCredits' */ '@/components/Fields/CourseCredits')
const DepartmentsField = () =>
  import(/* webpackChunkName: 'DepartmentsField' */ '@/components/Fields/DepartmentsField')
const DepartmentOwnershipField = () =>
  import(/* webpackChunkName: 'DepartmentOwnershipField' */ '@/components/Fields/DepartmentOwnershipField')
const LearningOutcomesField = () =>
  import(/* webpackChunkName: 'LearningOutcomesField' */ '@/components/Fields/LearningOutcomesField')
const Requisites = () =>
  import(/* webpackChunkName: 'Requisites' */ '@/components/Fields/Requisites/Requisites')
const DegreeMaps = () =>
  import(/* webpackChunkName: 'DegreeMaps' */ '@/components/Fields/DegreeMaps/DegreeMaps')
const ProgramSpecializations = () =>
  import(/* webpackChunkName: 'ProgramSpecializations' */ '@/components/Fields/ProgramSpecializations/ProgramSpecializations')
const Dependencies = () =>
  import(/* webpackChunkName: 'Dependencies' */ '@/components/Fields/Dependencies')
const CourseSchedule = () =>
  import(/* webpackChunkName: 'CourseSchedule' */ '@/components/Fields/CourseSchedule')
const RequirementGroupField = () =>
  import(/* webpackChunkName: 'RequirementGroupField' */ '@/components/Fields/RequirementGroupField')
const DefaultMultiField = () =>
  import(/* webpackChunkName: 'DefaultMultiField' */ '@/components/Fields/DefaultMultiField')
const HeadingField = () =>
  import(/* webpackChunkName: 'TitleField' */ '@/components/Fields/HeadingField')
const CrossListedCourses = () =>
  import(/* webpackChunkName: 'CrossListedCourses' */ '@/components/Fields/CrossListedCourses')
const CourseEquivalencies = () =>
  import(/* webpackChunkName: 'CourseEquivalencies' */ '@/components/Fields/CourseEquivalencies')
const ImageField = () =>
  import(/* webpackChunkName: 'ImageField' */ '@/components/Fields/ImageField')
const TextareaField = () =>
  import(/* webpackChunkName: 'TextareaField' */ '@/components/Fields/TextareaField')
const FileField = () =>
  import(/* webpackChunkName: 'FileField' */ '@/components/Fields/FileField')
const ProgramsSelectField = () =>
  import(/* webpackChunkName: 'ProgramsSelectField' */ '@/components/Fields/ProgramsSelectField')
const CoursesSelectField = () =>
  import(/* webpackChunkName: 'CoursesSelectField' */ '@/components/Fields/CoursesSelectField')
const CourseGroup = () =>
  import(/* webpackChunkName: 'CourseGroup' */ '@/components/Fields/CourseGroup')
const SyllabusSectionFiles = () =>
  import(/* webpackChunkName: 'SyllabusSectionFiles' */ '@/components/Fields/SyllabusSectionFiles')
const DocumentTypeItemsField = () =>
  import(/* webpackChunkName: 'DocumentTypeItemsField' */ '@/components/Fields/DocumentTypeItemsField')

export default function matchFieldType(fieldType, nestedFields) {
  switch (fieldType) {
    case 'credits':
      return CourseCredits
    case 'departmentSelect':
      return DepartmentsField
    case 'departmentOwnership':
      return DepartmentOwnershipField
    case 'requisites':
      return Requisites
    case 'degreeMaps':
      return DegreeMaps
    case 'specializations':
      return ProgramSpecializations
    case 'wysiwyg':
      return WysiwygField
    case 'termSelect':
      return TermField
    case 'learningOutcomes':
      return LearningOutcomesField
    case 'dependencies':
      return Dependencies
    case 'courseSchedule':
      return CourseSchedule
    case 'requirementGroupSelect':
      return RequirementGroupField
    case 'heading':
      return HeadingField
    case 'crossListedCourseSelect':
      return CrossListedCourses
    case 'courseEquivalenciesSelect':
      return CourseEquivalencies
    case 'uploadImage':
      return ImageField
    case 'textarea':
      return TextareaField
    case 'file':
      return FileField
    case 'programSelect':
      return ProgramsSelectField
    case 'courseSelect':
      return CoursesSelectField
    case 'courseGroupSelect':
      return CourseGroup
    case 'sy_files_course_sections':
      return SyllabusSectionFiles
    case 'documentItemSelect':
      return DocumentTypeItemsField
    default:
      if (nestedFields) {
        return DefaultMultiField
      } else {
        return DefaultField
      }
  }
}
