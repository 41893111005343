import {
  courseNameWithFallbacks,
  programNameWithFallbacks,
} from '@/utils/entityNames'

/*
Note: If a useCourse/useProgram composable file is ever written,
these functions can be moved to such a file
*/
function extractCourseLabels(course) {
  const code = course.code || `${course.subjectCode + course.courseNumber}`
  const name = courseNameWithFallbacks(course) || 'Untitled Course'
  return { code, name }
}

function generateCourseLabel(course, courseGroupId, options = {}) {
  const { getInterpolatedFieldValue, nameOnlyFallback } = options
  // find possible interpolated value from dictionary
  const interpolatedValue = getInterpolatedFieldValue
    ? getInterpolatedFieldValue('globalCourseTitle', course, 'course')
    : undefined
  if (interpolatedValue) return interpolatedValue

  // if courseGroupId is provided and no course is found, return courseGroupId
  // otherwise fallback to 'Course Not Found' text
  if (!course) return courseGroupId || 'Course Not Found'
  const { code, name } = extractCourseLabels(course)
  if (nameOnlyFallback) return name
  return code ? `${code} - ${name}` : name
}

function extractCourseCredits(course) {
  if (!course) return { min: undefined, max: undefined }

  let min = 0
  let max = 0
  if (course.credits?.creditHours?.min) {
    min = course.credits.creditHours.min
  }
  if (course.credits?.creditHours?.max) {
    max = course.credits.creditHours.max
  }

  return { min, max }
}

function generateCourseCreditsRange(course) {
  if (!course) return ''

  const { min, max } = extractCourseCredits(course)

  if (!min && !max) return ``
  if (!max && min) return `(${min} Cr.)`
  if (!min && max) return `(${max} Cr.)`
  return min === max ? `(${min} Cr.)` : `(${min} - ${max} Cr.)`
}

function extractProgramLabels(program) {
  const code = program.code
  const name = programNameWithFallbacks(program) || 'Untitled Program'
  return { code, name }
}

function generateProgramLabel(program, school, options = {}) {
  if (!program) return 'Program Not Found'
  const { code, name } = extractProgramLabels(program)
  const { getInterpolatedFieldValue, nameOnlyFallback } = options
  const interpolatedValue = getInterpolatedFieldValue
    ? getInterpolatedFieldValue('globalProgramTitle', program, 'program')
    : undefined
  if (interpolatedValue) return interpolatedValue
  switch (school) {
    case 'scsu':
    case 'hpu':
    case 'bar01': 
    case 'bcc01': 
    case 'bkl01': 
    case 'bmc01': 
    case 'csi01': 
    case 'cty01': 
    case 'grd01': 
    case 'hos01': 
    case 'htr01': 
    case 'jjc01': 
    case 'kcc01': 
    case 'lag01': 
    case 'law01': 
    case 'leh01': 
    case 'mec01': 
    case 'med01': 
    case 'mhc01': 
    case 'ncc01': 
    case 'nyt01': 
    case 'qcc01': 
    case 'qns01': 
    case 'slu01': 
    case 'soj01': 
    case 'sph01': 
    case 'sps01': 
    case 'yrk01': 
      return name
    default: {
      if (nameOnlyFallback) return name
      return code ? `${code} - ${name}` : name
    }
  }
}

export {
  extractCourseLabels,
  generateCourseLabel,
  extractCourseCredits,
  generateCourseCreditsRange,
  extractProgramLabels,
  generateProgramLabel,
}
