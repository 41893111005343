/**
 * Function copied from main repo (shared/utils/object.js#getAll)
 * Take all values for a given paths and creates flat array of them.
 * It omits paths not present in object
 * @param {Object} object - Any object or array
 * @param {String[]} paths - The array of paths
 * @return {Array} - The array of values
 * @example
 * getAll({ a: [ { b: 1 }, { b: 2 } ] }, [ 'a.*.b', 'c' ])
 * // returns [ 1, 2 ]
 */
export function getAll(obj = {}, paths = []) {
  function _step(value = {}, steps = []) {
    if (!steps.length) return value
    const isArr = Array.isArray(value)
    const valueNormalized = isArr ? value : [value]
    const [, step, ...rest] = steps[0] === '*' ? steps : ['*', ...steps]
    return _step(
      valueNormalized
        .reduce((acc, v) => {
          // eslint-disable-next-line
          if (typeof v === 'object' && !v.hasOwnProperty(step)) return acc
          acc.push(v[step])
          return acc
        }, [])
        .flat(),
      rest
    )
  }
  return paths.flatMap((path) => _step(obj, path.split('.')))
}
