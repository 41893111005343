<template>
  <div>
    <h3 class="field-label">{{ question.label }}</h3>
    <div class="field-value">{{ formattedValue }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: '',
    },
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedValue() {
      if (Array.isArray(this.value)) {
        return this.value.length
          ? this.value.join(', ')
          : this.emptyValuePlaceholder
      }
      if (typeof this.value === 'boolean') return this.value ? 'Yes' : 'No'
      if (this.value === null || this.value === undefined || this.value === '')
        return this.emptyValuePlaceholder
      else return this.value
    },
    emptyValuePlaceholder() {
      return (
        this.question?.config?.emptyValuePlaceholder ||
        this.question?.config?.default?.value ||
        '-'
      )
    },
  },
}
</script>
